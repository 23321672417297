import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { createTheme, ThemeProvider } from "@mui/material/styles";
const defaultTheme = createTheme();

export default function FileUpload(props) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      {/* <Typography variant="caption" align="left">
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            console.info("download the template");
          }}
          sx={{ textAlign: "left", textDecoration: "none" }}
        >
          Click here to download the claims template
        </Link>
      </Typography> */}
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      >
        {/*  <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            Company name
          </Typography>
        </Toolbar> */}
      </AppBar>
      <Container component="main" maxWidth="md" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          elevation="6"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100% !important",
            }}
          >
            <Input
            

              sx={{ width: "70% !important", margin: "auto" }}
              required
              type="file"
              id="file"
              name="file"
              variant="standard"
              onChange={(e) => props.handleInputTextChange(e)}
            />
          </Box>
          <Button
            variant="contained"
            sx={{ mt: 3, ml: 1 }}
            onClick={(e) => props.onUploadFile(e)}
          >
            Validate
          </Button>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
