import React from "react";
import "./Button.css";

function Button(props) {
  return (
    <button
      className={props.Style}
      type="submit"
      value={props.Value.toLowerCase()}
      onClick={props.onClick}
      disabled={props.isdisabled}
    >
      {props.Value}
    </button>
  );
}
export default Button;
