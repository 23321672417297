import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Box, Divider } from "@material-ui/core";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Cookies from "js-cookie";
import { MdImportContacts } from "react-icons/md";
import "./Bayan.css";
import {
  FaFileAlt,
  FaRegUser,
  FaHistory,
  FaFileCsv,
  FaHome,
  FaWpforms,
  FaUpload,
  FaUserPlus,
  FaUsers,
  FaCubes,
  FaSignOutAlt,
  FaClock,
  FaTrademark,
  FaInbox,
  FaCodeBranch,
  FaLevelDownAlt,
  FaNetworkWired,
  FaFileSignature,
} from "react-icons/fa";
import IconMenuClose from "../KareemBenefits/icons/icon-close.svg";
import IconMenuOpen from "../KareemBenefits/icons/icon-open.svg";

const drawerWidth = 270;
const useStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: 1000,
    width: drawerWidth,
    flexShrink: 0,
    // whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(8) + 1,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    marginTop: theme.spacing(6.5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(10),
    },
  },
}));

export default function Sub({
  toggleState,
  toggleTab,
  name,
  projectType,
  history,
  children,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  // const [showAboutMenu1, setShowAboutMenu1] = React.useState(false);
  const handleLogout = (history) => {
    // let tokenName =
    //   projectType == "Sanad" || projectType == "Huda"
    //     ? "SanadId"
    //     : projectType == "Kareem"
    //     ? "KareemId"
    //     : projectType == "Sama" || projectType == "Somod"
    //     ? "SamaId"
    //     : projectType == "admin"
    //     ? "BayanAdminID"
    //     : projectType == "SamaCompanyAdmin"
    //     ? "SamaCompanyId"
    //     : projectType == "samaAdmin"
    //     ? "SamaAdminId"
    //     : "id";
    // Cookies.remove(tokenName);
    Cookies.remove("id");
    // Object.keys(localStorage).forEach((item) => {
    //   if (
    //     projectType !== "Sanad" &&
    //     projectType !== "Sama" &&
    //     projectType != "admin"
    //   ) {
    //     if (
    //       !item.includes("Sanad") &&
    //       !item.includes("Sama") &&
    //       !item.includes("Admin")
    //     ) {
    //       localStorage.removeItem(item);
    //     }
    //   } else if (projectType == "Sama") {
    //     if (item.includes("Sama")) {
    //       localStorage.removeItem(item);
    //     } else if (projectType == "Kareem") {
    //       if (item.includes("Kareem")) {
    //         localStorage.removeItem(item);
    //       }
    //     }
    //   } else if (projectType == "admin") {
    //     if (item.includes("Admin")) {
    //       localStorage.removeItem(item);
    //     }
    //   } else if (projectType == "samaAdmin") {
    //     if (item.includes("SamaAdminId")) {
    //       localStorage.removeItem(item);
    //     }
    //   } else {
    //     if (item.includes("Sanad")) {
    //       localStorage.removeItem(item);
    //     }
    //   }
    // });

    history.push("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const iconMenu = [
    <FaUpload />,
    <FaWpforms />,
    <FaHome />,
    <FaFileCsv />,
    <FaHistory />,
    <FaRegUser />,
    <FaSignOutAlt />,
    <FaUsers />,
    <FaInbox />,
    <FaCubes />,
    <FaTrademark />,
    <FaCodeBranch />,
    <FaClock />,
    <FaUserPlus />,
    <FaFileAlt />,
    <MdImportContacts />,
    <FaLevelDownAlt style={{ marginLeft: "4.5rem" }} />,
    <FaNetworkWired />,
    <FaFileSignature />,
  ];
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        id="barra-lateral"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <img src={IconMenuClose} alt="close menu" />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen}>
              <img src={IconMenuOpen} alt="open menu" />
            </IconButton>
          )}
        </div>
        <Divider />
        <List>
          <ListItem>
            <ListItemIcon>{iconMenu[5]}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
          <ListItem
            onClick={() => toggleTab(1)}
            className={toggleState === 1 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[2]}</ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          
          <ListItem
            onClick={() => toggleTab(5)}
            className={toggleState === 5 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[14]}</ListItemIcon>
            <ListItemText primary="File Upload" />
          </ListItem>

         

          <ListItem
            onClick={() => toggleTab(6)}
            className={toggleState === 6 ? "tabActive" : ""}
          >
            <ListItemIcon>{iconMenu[4]}</ListItemIcon>
            <ListItemText primary="Form Upload" />
          </ListItem>


          <ListItem
            className="sign-out"
            onClick={() => handleLogout(history)}
          >
            <ListItemIcon>{iconMenu[6]}</ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" flexGrow={1}>
        {children}
      </Box>
    </Box>
  );
}
