import * as React from "react";
import { useEffect, useRef } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LogoutIcon from "@mui/icons-material/Logout";
//import FileUploadIcon from "@mui/icons-material/FileUpload";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DescriptionIcon from "@mui/icons-material/Description";
import CircularProgress from "@mui/material/CircularProgress";
import EditsTable from "./EditsTable";
import Link from "@mui/material/Link";
import * as XLSX from "xlsx";
import axios from "axios";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Alert from "@mui/material/Alert";
//import IconButton from '@mui/material/IconButton';
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";

import FileUpload from "./fileUpload";
import FormUpload from "./formUpload";
import settings from "../helpers/Settings";
import "./BayanKSA.css";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
let { KayanURL } = settings;
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const [count, setCount] = React.useState(null);

  const [open, setOpen] = React.useState(true);
  const [screenName, setScreenName] = React.useState("fileUpload");
  const [step, setStep] = React.useState(0);
  const [excelRows, setExcelRows] = React.useState("");
  const [requestSent, setRequestSent] = React.useState(false);
  const [responseReady, setResponseReady] = React.useState(false);
  const [freeOfEdits, setFreeOfEdits] = React.useState(false);
  const [failed, setFailed] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);

  const [validationEdits, setValidationEdits] = React.useState([]);
  const [age, setAge] = React.useState(0);
  const [ageUnit, setAgeUnit] = React.useState("");

  const [gender, setGender] = React.useState("");

  const [encounterType, setEncounterType] = React.useState("");

  const [dataObj, setDataObj] = React.useState({});

  const [diagnosisObj, setDiagnosisObj] = React.useState({});
  let [AllDiagnosis, setAllDiagnosis] = React.useState([]);

  const [activityObj, setActivityObj] = React.useState({});
  const [AllActivity, setAllActivity] = React.useState([]);

  const [AllMasterDiagnosis, setAllMasterDiagnosis] = React.useState([]);

  const [AllMasterActivity, setAllMasterActivity] = React.useState([]);
  const [flag, setFlag] = React.useState(false);
  const history = useHistory();

/*   useEffect(async () => {
    if (!Cookies.get("sayanID") || Cookies.get("sayanID") != "eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ..CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs") {
      history.push("/sayan/login");
    }
    let getAllDiagnosisResult = await axios.put(
      KayanURL + "api/ksa/getAllDiagnosis/",
      {}
    ); //try

    if (getAllDiagnosisResult) {
      let AllMasterDiagnosis = getAllDiagnosisResult["data"];

      setAllMasterDiagnosis(AllMasterDiagnosis);
    }
  }, []); */


  useEffect(() => {
    const checkAuth = async () => {
      if (!Cookies.get("sayanID") || Cookies.get("sayanID") != "eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ..CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs") {
        history.push("/sayan/login");
      }
    };

    checkAuth();

    const callAPI=async()=>{
      let getAllDiagnosisResult = await axios.put(
        KayanURL + "api/ksa/getAllDiagnosis/",
        {}
      ); //try
  
      if (getAllDiagnosisResult) {
        let AllMasterDiagnosis = getAllDiagnosisResult["data"];
  
        setAllMasterDiagnosis(AllMasterDiagnosis);
      }
    }

    callAPI()

   


  }, [history]);

  const getAllMasterActivity = async (type) => {
    console.log("get master list");
    console.log(type);

    let getAllActivityResult = await axios.put(
      KayanURL + `api/ksa/getAllActivity/${type}`,
      {}
    ); //try

    //console.log("getAllActivityResult", getAllActivityResult);
    if (getAllActivityResult) {
      let AllMasterActivity = getAllActivityResult["data"];
      setAllMasterActivity(AllMasterActivity);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  ///////////////////////
  function switchPage(selectName) {
    if (selectName && selectName == "Bulk Validation") {
      setScreenName("fileUpload");
    } else if (selectName && selectName == "Claim Validation") {
      setScreenName("formUpload");
      setStep(0);
    }
    setResponseReady(false);
    setRequestSent(false);
    setDataObj({});
  }
  /////////////////////
  async function onUploadFile(event) {
    //console.log("on Upload File");

    event.preventDefault();
    setOpenAlert(false);
    setFailed(false);
    setRequestSent(true);

    const result = await axios.post(KayanURL + "api/ksa/validation/", {
      data: excelRows,
    }); //try

    if (result) {
      setExcelRows("");
      let { data } = result;

      let { success, error } = data;
      if (success) {
        setRequestSent(false);
        setResponseReady(true);

        setFreeOfEdits(true);
      } else if (!success && error) {
        setResponseReady(true);
        setRequestSent(false);

        setFailed(true);
        setOpenAlert(true);
        setErrorMsg(error);
      } else {
        setRequestSent(false);
        setResponseReady(true);
        let { validationErrors } = data;

        console.log("validationErrors", validationErrors);
        if (validationErrors && validationErrors.length > 0) {
          setValidationEdits(validationErrors);
        }
      }
    }

    document.getElementById("file").value = "";
  }

  /////////////////////

  async function onValidateForm(event) {
    event.preventDefault();
    setOpenAlert(false);
    let generatedClaimNo = Math.floor(1000 + Math.random() * 9000).toString();
    let data = [];
    var row = {};
    if (!dataObj["AllDiagnosis"] || dataObj["AllDiagnosis"].length == 0) {
      //alert("Please enter one diagnoaia at least!");
      setOpenAlert(true);
      setErrorMsg("Make sure to enter one diagnosis details at least!");
    } else if (!dataObj["AllActivity"] || dataObj["AllActivity"].length == 0) {
      setOpenAlert(true);
      setErrorMsg("Make sure to enter one activity details at least!");
    } else {
      setRequestSent(true);
      row["claim_ID"] = generatedClaimNo;

      if (dataObj["birthdate"]) {
        let [year, month, day] = dataObj["birthdate"].split("-");

        let dob = day + "/" + month + "/" + year;
        row["patient_dob"] = /*  dataObj["birthdate"] */ dob;
      } else {
        row["patient_dob"] = undefined;
      }

      row["patient_gender"] = dataObj["gender"];
      row["encounter_type"] = dataObj["encounterType"]

      let PrincipalDiagnosisObj = dataObj["AllDiagnosis"].filter(
        (obj) => obj.diagnosisType == "principal"
      );

      if (PrincipalDiagnosisObj && PrincipalDiagnosisObj.length > 0) {
        row["primary_diagnosis_code"] =
          PrincipalDiagnosisObj[0].diagnosisCode.split("-")[0];
      } else {
        row["primary_diagnosis_code"] = "";
      }

      //extract Secondary diagnosis

      let secondaryDiagnosisObjs = dataObj["AllDiagnosis"].filter(
        (obj) => obj.diagnosisType == "secondary"
      );
      if (secondaryDiagnosisObjs && secondaryDiagnosisObjs.length > 0) {
        let secondayDiagnosisStr = [];
        secondaryDiagnosisObjs.forEach((item) => {
          secondayDiagnosisStr.push(item.diagnosisCode.split("-")[0]);
        });
        row["secondery_diagnosis_codes"] = secondayDiagnosisStr.toString();
      } else {
        row["secondery_diagnosis_codes"] = "";
      }

      if (dataObj["AllActivity"] && dataObj["AllActivity"].length > 0) {
        let firstservice = dataObj["AllActivity"][0];
        let { activityType, activityQuantity, activityCode, toothNumber } = firstservice;

        row["service_type"] = activityType ? activityType : "";
        row["service_code"] = activityCode.split(",")[0];
        row["service_quantity"] = activityQuantity ? activityQuantity : 1;
        row["service_tooth_number"] = activityType == "dental" ? toothNumber : ""
      }

      data.push(row);

      if (dataObj["AllActivity"] && dataObj["AllActivity"].length > 1) {
        for (let index = 1; index < dataObj["AllActivity"].length; index++) {
          let row = {};
          let service = dataObj["AllActivity"][index];
          let { activityType, activityQuantity, activityCode, toothNumber } = service;
          row["claim_ID"] = generatedClaimNo;

          row["service_type"] = activityType ? activityType : "";
          row["service_code"] = activityCode.split(",")[0];
          row["service_quantity"] = activityQuantity ? activityQuantity : 1;
          row["service_tooth_number"] = activityType == "dental" ? toothNumber : ""

          data.push(row);
        }
      }

      console.log("data", data);

      const result = await axios.post(KayanURL + "api/ksa/validation/", {
        data,
      }); //try

      //console.log("result", result);
      displayResponse(result);
    }
  }

  function displayResponse(result) {

    if (result) {
      setRequestSent(false);
      setResponseReady(true);
      let { data } = result;
      if (data && data.success) {
        console.log("free of edits ");
        setFreeOfEdits(true);
      } else {
        let { validationErrors } = data;
        console.log("validationErrors display response", validationErrors);
        if (validationErrors && validationErrors.length > 0) {
          setValidationEdits(validationErrors);
        }
      }
    }
  }

  //////////////////

  function processExcel(data) {
    const workbook = XLSX.read(data, { type: "binary" });
    const firstSheet = workbook.SheetNames[0];
    const rows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet]
    );
    let excelRows = JSON.stringify(rows);

    setExcelRows(excelRows);
  }

  /////////////////////////
  const handleInputTextChange = ({ target: { name, value, files } }) => {
    console.log("handle input text change");
    setOpenAlert(false);
    setExcelRows("");

    const regex = /^.*\.(xls|xlsx|csv)$/;
    //console.log(files[0]);

    if (files[0] && regex.test(files[0].name.toLowerCase())) {
      let fileName = files[0].name;
      //console.log("file name", fileName);
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          processExcel(reader.result);
        };
        reader.readAsBinaryString(files[0]);
      }
    } else if (files[0]) {
      setOpenAlert(true);
      setErrorMsg(
        "It seems you've tried to upload a file with an unsupported extension. Currently, we only accept the following file types: .xlsx, .xls, csv"
      );
    }
  };
  /////////////////////

  const onSelectDiagnosisCode = (value) => {
    diagnosisObj["diagnosisCode"] = value /* [0] */;
    setDiagnosisObj(diagnosisObj);
    setFlag(false);
  };

  const onSelectActivityCode = (value) => {
    activityObj["activityCode"] = value;
    setActivityObj(activityObj);
    setFlag(false);
  };

  const generateAgeUnit = (date) => {
    date = new Date(date);

    //calculate month difference from current date in time
    var month_diff = Date.now() - date.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    var age = Math.abs(year - 1970);

    setAge(age);
    dataObj["age"] = age;

    setDataObj(dataObj);
  };

  const download = (e) => {
    //download response js as xlsx file
    // console.log(rows);
    e.preventDefault();
    const worksheet1 = XLSX.utils.json_to_sheet(
      /* JSON.parse(rows) */ /* rows */ validationEdits
    );
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    XLSX.writeFile(workbook1, "report.xlsx");
  };

  const handleInputChangeFormScreen = ({ target: { name, value, files } }) => {
    setCount(true)
    switch (name) {
      case "birthdate":
        dataObj["birthdate"] = value;

        setDataObj(dataObj);
        generateAgeUnit(value);

        break;

      case "gender":
        dataObj["gender"] = value;
        setGender(value);
        setDataObj(dataObj);
        while (count) {
          setCount(false);
          //console.log("count", count);
          //handleInputChangeFormScreen({ target: { name, value, files } });

          break;
        }
        break;

      case "encounterType":
        dataObj["encounterType"] = value;
        setEncounterType(value);
        setDataObj(dataObj);
        while (count) {
          setCount(false);
          //console.log("count", count);

          break;
        }

      case "diagnosisCode":
        //filter the suggestion
        /* diagnosisObj["diagnosisCode"] = value;
        setDiagnosisObj(diagnosisObj); */
        break;

      case "diagnosisType":
        diagnosisObj["diagnosisType"] = value;
        setDiagnosisObj(diagnosisObj);
        console.log("diagnosis obj", diagnosisObj);

        break;

      case "activityType":
        activityObj["activityType"] = value;
        setActivityObj(activityObj);
        getAllMasterActivity(value);
        break;

      case "activityCode":
        activityObj["activityCode"] = value;
        setActivityObj(activityObj);
        break;

      case "activityPrice":
        activityObj["activityPrice"] = value;
        setActivityObj(activityObj);
        break;

      case "activityQuantity":
        activityObj["activityQuantity"] = value;
        setActivityObj(activityObj);
        break;

      case "toothNumber":
        activityObj["toothNumber"] = value;
        setActivityObj(activityObj);
        break;

      default:
        break;
    }
  };

  const onClickAddDiagnosis = (e) => {
    console.log("add new diagnosis obj");

    console.log(diagnosisObj);

    console.log("AllDiagnosis", AllDiagnosis);

    //check if the principal type already added, and the new type is principal also.

    if (diagnosisObj && diagnosisObj.diagnosisType == "principal") {
      console.log("heeeeeeeeere");
      let filteredPrincipal = AllDiagnosis.filter(
        (icd) => icd.diagnosisType == "principal"
      );

      console.log("filteredPrincipal", filteredPrincipal);
      if (filteredPrincipal && filteredPrincipal.length > 0) {
        //alert
        console.log("alert");
        alert("Make sure to use one principal diagnosis.");
      } else {
        AllDiagnosis.push(diagnosisObj);
        setAllDiagnosis(AllDiagnosis);
        dataObj["AllDiagnosis"] = AllDiagnosis;
        setDataObj(dataObj);
        setDiagnosisObj({});
        setFlag(true);
      }
    } else {
      AllDiagnosis.push(diagnosisObj);
      setAllDiagnosis(AllDiagnosis);
      dataObj["AllDiagnosis"] = AllDiagnosis;
      setDataObj(dataObj);
      setDiagnosisObj({});
      setFlag(true);
    }
  };

  const onClickAddActivity = (e) => {
    console.log("add new activity obj");

    AllActivity.push(activityObj);
    setAllActivity(AllActivity);
    dataObj["AllActivity"] = AllActivity;
    setDataObj(dataObj);
    setActivityObj({});
    setFlag(true);
    setAllMasterActivity([]);
  };

  const clearResponse = (e) => {
    setRequestSent(false);
    setResponseReady(false);
    // setDataObj({ gender: "M" });
    setDataObj({});
    setActivityObj({});
    setDiagnosisObj({});
    setAllDiagnosis([]);
    setAllActivity([]);
    setCount(true);
  };

  const deleteDiagnosisElement = (index) => {
    let newArray = [];
    if (AllDiagnosis && AllDiagnosis.length > 0) {
      AllDiagnosis.forEach((element, i) => {
        if (i != index) {
          newArray.push(element);
        }
      });

      setAllDiagnosis(newArray);
      dataObj["AllDiagnosis"] = newArray;
      setDataObj(dataObj);
    }
  };

  const deleteActivityElement = (index) => {
    let newArray = [];
    if (AllActivity && AllActivity.length > 0) {
      AllActivity.forEach((element, i) => {
        if (i != index) {
          newArray.push(element);
        }
      });

      setAllActivity(newArray);
      dataObj["AllActivity"] = newArray;
      setDataObj(dataObj);
    }
  };

  const logout = (e) => {
    Cookies.remove("sayanID");
    window.location.href = "/";

    //history.push("/")
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" color={"#1976d2"}>
            Sayan
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem key="Welcome" disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Welcome test" />
            </ListItemButton>
          </ListItem>
          {["Bulk Validation", "Claim Validation"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <Link
                style={{ color: "white", textDecoration: "none" }}
                component="button"
                onClick={() => switchPage(text)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    {index == 0 ? <UploadFileIcon /> : <DescriptionIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
          <Divider />

          <ListItem
            key="sign out"
            disablePadding
          // sx={{ position: "fixed", bottom: 0 }}
          >
            <ListItemButton
              onClick={(e) => {
                logout(e);
              }}
            >
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />

        {(responseReady && !requestSent && openAlert) || openAlert ? (
          /*   <Alert style={{ width: "50%", margin: "auto" }} severity="error">
            {errorMsg}
          </Alert> */
          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {errorMsg}
            </Alert>
          </Collapse>
        ) : (
          <></>
        )}
        {screenName == "fileUpload" ? (
          <FileUpload
            onUploadFile={onUploadFile}
            handleInputTextChange={handleInputTextChange}
          />
        ) : (
          <FormUpload
            flag={flag}
            clearResponse={clearResponse}
            onValidate={onValidateForm}
            onChange={handleInputChangeFormScreen}
            claimData={dataObj}
            gender={gender}
            onClickAddDiagnosis={onClickAddDiagnosis}
            onClickAddActivity={onClickAddActivity}
            step={step}
            deleteDiagnosisElement={deleteDiagnosisElement}
            deleteActivityElement={deleteActivityElement}
            diagnosisObj={diagnosisObj}
            activityObj={activityObj}
            AllMasterDiagnosis={AllMasterDiagnosis}
            AllMasterActivity={AllMasterActivity}
            onSelectDiagnosisCode={onSelectDiagnosisCode}
            onSelectActivityCode={onSelectActivityCode}
          />
        )}

        {requestSent && !responseReady ? (
          <CircularProgress />
        ) : responseReady && !requestSent && freeOfEdits ? (
          <Typography variant="h5" sx={{ color: "green" }}>
            Free of Edits.
          </Typography>
        ) : responseReady &&
          !requestSent &&
          !freeOfEdits &&
          !openAlert &&
          !failed ? (
          <EditsTable onDownload={download} rows={validationEdits} />
        ) : null}
      </Box>
    </Box>
  );
}
