import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import * as XLSX from "xlsx";

export default function EditsTable(props) {
  // console.log(props.rows);
  let { rows } = props;

  function download() {
    //download response js as xlsx file
    // console.log(rows);
    const worksheet1 = XLSX.utils.json_to_sheet(/* JSON.parse(rows) */ rows);
    const workbook1 = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook1, worksheet1, "Sheet1");
    XLSX.writeFile(workbook1, "report.xlsx");
  }
  return (
    <TableContainer
      sx={{
        /* minWidth: 650 */ width: "70%",
        margin: "10px auto",
        "& td": { border: 0 },
      }}
      component={Paper}
    >
      <Table aria-label="simple table">
        <caption sx={{ captionSide: "top !important" }}>
          <Link onClick={(e) => props.onDownload(e)}>
            Click here to download the report
          </Link>
        </caption>

        <TableHead
          sx={{
            backgroundColor: "#1976d2 !important",
            color: "white !important",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Claim ID
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Domain
            </TableCell>{" "}
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Code
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Edit Type
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Message
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Level
            </TableCell>
            <TableCell
              sx={{
                color: "white !important",
              }}
              align="center"
            >
              Denial Code
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="center">{row.ClaimID}</TableCell>
              <TableCell align="center">{row.Domain}</TableCell>
              <TableCell align="center">{row.Code}</TableCell>

              <TableCell align="center">{row.Type}</TableCell>
              <TableCell align="center">{row.Message}</TableCell>
              <TableCell align="center">{row.Level}</TableCell>

              <TableCell align="center">{row.Denial}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
