import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Footer from "../Components/footer";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import {useHistory} from 'react-router-dom';
import Cookies from "js-cookie";
import NavBar from "../Components/navbar";


const defaultTheme = createTheme();

export default function SignIn() {
  const history = useHistory();

  const [username, setUserName] = React.useState("");
  const [openAlert, setOpenAlert] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      username == "test_user" &&
      password == "test@sayan123"
    ) {

     /********
      * 
      */
      Cookies.set("sayanID","eyJhbGciOiJIUzI1NiJ9.ZW1wbG95ZWU6MQ..CLEKBu7BpY9VxZVXhGR6BMFYWPJ1FWMR3-s9kwkcHXs")
      history.push("/sayan/home")


    } else if (username != "test_user" && password != "test@sayan123") {
      setOpenAlert(true)
      setError("Incorrect Username and Password");
    } else if (username == "test_user" && password != "test@sayan123") {
      setOpenAlert(true)
      setError("Incorrect Password");
    } else if (username != "test_user" && password == "test@sayan123") {
      setOpenAlert(true)
      setError("Incorrect Username");
    }
  };

  const handleInputTextChange = ({ target: { name, value } }) => {
    setOpenAlert(false);

    switch (name) {
      /*  case "companyName":
        setCompanyName(value);
        break; */

      case "username":
        setUserName(value);
        break;

      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  return (
    
    <ThemeProvider theme={defaultTheme}>
            <NavBar />

      <Container component="main" /*  maxWidth="xs" */>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            marginBottom: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/*    */}
          {/*   {error ? (
            <Typography component="h1" variant="h5">
              {error}
            </Typography>
          ) : null} */}

          <Collapse in={openAlert}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpenAlert(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {error}
            </Alert>
          </Collapse>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography component="h1" variant="h5">
              Welcome to Sayan
            </Typography>
            {/*    <TextField
              margin="normal"
              required
              fullWidth
              id="companyName"
              label="Company Name"
              name="companyName"
              autoFocus
              onChange={(event) => {
                //this.setState({ Company: event.target.value });
                handleInputTextChange(event);
              }}
            />
 */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="username"
              autoFocus
              onChange={(event) => {
                // this.setState({ username: event.target.value });
                handleInputTextChange(event);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(event) => {
                // this.setState({ password: event.target.value });
                handleInputTextChange(event);
              }}
            />

            <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </ThemeProvider>
  );
}
