import React, { Component, Fragment } from "react";
//import './styles.css'
import TextField from "@mui/material/TextField";

class Autocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: "",
      onChangeFlag: false,
    };
  }

  onChange = (e) => {
    console.log("on change");
    const { suggestions } = this.props;
    console.log("suggestions", suggestions);
    const userInput = e.currentTarget.value;
    this.setState({
      userInput: e.currentTarget.value,
      onChangeFlag: true,
    });
    if (userInput.length > 1) {
      const filteredSuggestions = suggestions.filter(
        (suggestion) =>
          suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      this.setState({
        activeSuggestion: 0,
        filteredSuggestions,
        showSuggestions: true,
        userInput: e.currentTarget.value,
      });

      this.props.onChangeSetCode(e.currentTarget.innerText);
    }
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      onChangeFlag: false,
    });
    this.props.onSelectCode(e.currentTarget.innerText);
    this.props.onChangeSetCode(e.currentTarget.innerText);
  };

  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    } else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }
              return (
                <li
                  /* className={className} */ key={suggestion}
                  onClick={onClick}
                  style={{ color: "white", background: "#1976d2" }}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No suggestions available.</em>
          </div>
        );
      }
    }
    /*  console.log(this.props.flag);
    console.log(this.state.onChangeFlag); */
    return (
      <Fragment>
        <TextField
          required
          id={this.props.id}
          label={this.props.label}
          name={this.props.name}
          fullWidth
          value={this.props.flag && !this.state.onChangeFlag ? "" : userInput}
          variant="standard"
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        {suggestionsListComponent}
      </Fragment>
    );
  } //
} //

export default Autocomplete;
