import React, { useState } from "react";
import Footer from "../Components/footer";
// import MainPageFooter from '../Components/MainPageFooter'
import NavBar from "../Components/navbar";
import "../Components/Styling/providerSolutions.css";
const ProviderSolutions = () => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);//
  };
  return (
    <>
      <NavBar />
      <div
        className="container allSol"
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          width: "100%",
          fontFamily: "Poppins,sans-serif",
        }}
      >
        <div className="bloc-tabs">
          <button
            className={toggleState === 1 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(1)}
          >
            KAREEM BENEFITS
          </button>
          <button
            className={toggleState === 2 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(2)}
          >
            BAYAN
          </button>
          <button
            className={toggleState === 3 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(3)}
          >
            SANAD
          </button>
          
          <button
            className={toggleState === 4 ? "tabs active-tabs" : "tabs"}
            onClick={() => toggleTab(4)}
          >
            SAMA
          </button>

       {/*    <button
            className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
           onClick={ () => toggleTab(5)}
            //event =>  window.location.href='/BayanKSA'}
          >
          Sayan
          </button> */}
         

        </div>

        <div className="content-tabs">
          <div
            className={
              toggleState === 1 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  Kareem Benefit's is an assistance system that helps in
                  creating clean claims that adhere to medical and coding
                  guidelines. Kareem Benefits supports healthcare providers with
                  high quality suggestions to minimize denial rates and to
                  provide the right health services. Kareem Benefit's is
                  currently being used by several clients. Kareem Benefit's
                  contains 25+ million edit combinations, and more to be
                  extended over time.
                </p>
                <span className="spantext">Kareem's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Has access to continuously updating General coding Rules
                    such as general exclusions.
                  </li>
                  <li>
                    Ability to add any custom rule through an easy-to-use
                    interface with maximum flexibility with customization.
                  </li>
                  <li>
                    Immediate advisory messages that signify any possible
                    rejected code based on the insurance and contract guideline.
                    Moreover, it helps in identifying the closest correct
                    matches.
                  </li>
                  <li>Use of historical data on the claim level.</li>
                </u>
              </div>

              <div className="left-provider">
                {" "}
                Kareem Benefits is an assistance system that helps in creating
                clean claims that adhere to medical, coding guidelines and
                benefit checks.
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Bayan Logo"
                  src={require("../imgs/Kareem_logo1.jpg")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/Kareem/login" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>
          <div
            className={
              toggleState === 2 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  BAYAN is a provider solution that relies on a huge database of
                  25 million+ combinations to gives valuable feedback that helps
                  in delivering clean claims that adhere to medical and coding
                  guidelines. Using BAYAN improves health services and cash flow
                  through minimizing rejections that stem from medical and
                  coding errors.
                </p>
                <span className="spantext">Bayan's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Helps coding teams in detecting unexpected rejections such
                    as incomplete and invalid coding.
                  </li>
                  <li>
                    Checks and ensures correct, accurate, and consistent coding
                    selections according to coding guidelines and standards.
                  </li>
                  <li>Minimizes irrelevant coding.</li>
                  <li>
                    Immediate advisory messages for possible irrelevant codes
                    while helping in identifying the closest correct codes
                  </li>
                  <li>
                    Excessively improves medical coding accuracy and validity by
                    reducing inappropriate coding.
                  </li>
                  <li>
                    Reduces significantly the time that insurance and coding
                    team spends in the process.
                  </li>

                  <li>
                    Helps in improving the documentations by minimizing any
                    potential mismatches that lead to potential inspections or
                    don’t meet the Audit process requirements.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  BAYAN is a provider solution that helps in delivering clean
                  claims that adhere to medical and coding guidelines.
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Bayan Logo"
                  src={require("../imgs/Bayan_logo1.png")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/loginAsEmployee" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>

          <div
            className={
              toggleState === 3 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  SANAD is a technical tool to calculate the DRG payment as per
                  the defined guidelines by the regulators. The solution has the
                  information and schema linked with all the data elements. It
                  is built with complete APIs for integration with HIS.
                </p>
                <span className="spantext">Sanad's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Helps in Minimizing the errors by giving clean calculations
                    for every IP-DRG claims.
                  </li>
                  <li>
                    An intelligent tool that provides information to the
                    provider for claim improvements.
                  </li>

                  <li>
                    Calculates the DRG based on the input format (*.xml or
                    *.xls/xlsx) to provide the following:
                    <ol style={{ marginLeft: "1.2rem" }}>
                      <li>
                        DRG values in terms of inlier, outlier, high-cost items
                        & high-cost drugs.
                      </li>
                      <li>Calculates the value for transfer cases.</li>
                      <li>
                        Gives errors and information to Provider (e.g.,
                        duplicate services, a discrepancy in the length of
                        stay).
                      </li>
                    </ol>
                  </li>

                  <li>
                    SANAD gives a response to the provider about the details of
                    outcomes against the input data.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  SANAD is a technical tool to calculate the DRG payment as per
                  the defined guidelines by the regulators.
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Sanad Logo"
                  src={require("../imgs/Sanad_logo1.png")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/Sanad/loginAsEmployee" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>

          <div
            className={
              toggleState === 4 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  SAMA is a technical business intelligence tool that provides
                  logical references to claims based on various clinical and
                  technical parameters. SAMA reads the claims and provides
                  outcomes based on various audit references and identifies the
                  claims that has potential risk in claim processing. In
                  addition, it provides interactive information on each claim
                  based on the content of the claims.
                </p>
                <span className="spantext">Sama's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>RCM with insurance Benefits edits.</li>
                  <li>Use of historical data at a claim level.</li>
                  <li>
                    Machine Learning process to analyze historical data and
                    start giving predictions (Financial and medical prediction).
                  </li>
                  <li>
                    {" "}
                    successful business excels in keeping track of a financial
                    record of all claims provided for its clients. Likewise,
                    these features are considered in claim analysis which help
                    in billing . Moreover, this analysis extends to the degree
                    of authorization of a claim.
                  </li>
                  <li>
                    Monitoring the outcome of a claim processing is crucial for
                    tracking the progress, and efficiency of SAMA as a system.
                  </li>
                  <li>
                    SAMA assesses how payers , doctors in provider entities
                    react to claims to establish the main aim of the system,
                    which is the overall reduction of rejected claims.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  SAMA is RCM system that provides logical references to claims
                  based on various clinical and technical parameters
                </p>
              </div>
              <div className="right-provider">
                <img
                  className="img"
                  alt="Samam Logo"
                  src={require("../imgs/Sama_logo1.png")}
                />
              </div>
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="/Sama/login" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>

          <div
            className={
              toggleState === 5 ? "content  active-content" : "content"
            }
          >
            <div className="flexinfo">
              <div className="left-provider" style={{ display: "none" }}>
                <p className="text">
                  BAYAN KSA is a provider solution that relies on a huge database of
                  25 million+ combinations to gives valuable feedback that helps
                  in delivering clean claims that adhere to medical and coding
                  guidelines. Using BAYAN improves health services and cash flow
                  through minimizing rejections that stem from medical and
                  coding errors.
                </p>
                <span className="spantext">Bayan's Features:</span>
                <u className="features" style={{ textDecoration: "none" }}>
                  <li>
                    Helps coding teams in detecting unexpected rejections such
                    as incomplete and invalid coding.
                  </li>
                  <li>
                    Checks and ensures correct, accurate, and consistent coding
                    selections according to coding guidelines and standards.
                  </li>
                  <li>Minimizes irrelevant coding.</li>
                  <li>
                    Immediate advisory messages for possible irrelevant codes
                    while helping in identifying the closest correct codes
                  </li>
                  <li>
                    Excessively improves medical coding accuracy and validity by
                    reducing inappropriate coding.
                  </li>
                  <li>
                    Reduces significantly the time that insurance and coding
                    team spends in the process.
                  </li>

                  <li>
                    Helps in improving the documentations by minimizing any
                    potential mismatches that lead to potential inspections or
                    don’t meet the Audit process requirements.
                  </li>
                </u>
              </div>

              <div className="left-provider">
                <p className="text">
                  Sayan is a payer solution that helps in delivering clean
                  claims that adhere to medical and coding guidelines.
                </p>
              </div>
       
            </div>
            <div className="btns">
              <a href="/" className="leftBtn">
                <button style={{ float: "left" }} className="btn movement">
                  Back Home
                </button>
              </a>
              <a href="sayan/login" className="rightBtn">
                <button style={{ float: "right" }} className="btn movement">
                  Go to Solution
                </button>
              </a>
            </div>
          </div>



        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProviderSolutions;
