import React, { Component } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import settings from "../helpers/Settings";
import NewForm from "../Components/FormNew/NewForm";
import Table from "react-bootstrap/Table";
import { CSVLink } from "react-csv";
import { MenuItem, Select as MuiSelect } from "@material-ui/core";
import selectType from './styleBayan.module.css';
let { KayanURL } = settings;

class MainScreenBayan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ActivityItems: [],
      ActivitySearch: [],
      ActivityItems_Search: [],
      code: [],
      ActivityNow1: 0,
      fileName: "",
      BayanHeader: [],
    };
    this.handleFromDate = this.handleFromDate.bind(this);
  }

  handleInputTextChange = ({ target: { name, value } }) => {
    switch (name) {
      case "type":
        this.setState({
          [name]: value,
        });
        if (value) {
          this.getAllActivity(value);
        }

        break;
      default:
        this.setState({
          [name]: value,
        });
        break;
    }
  };

  getAllActivity = (v) => {
    let ActivityItems = [];
    this.renderActivityProgressBar_Search();
    let typeText;
    switch (v) {
      case "cpt":
        typeText = 3;
        break;
      case "hcpcs":
        typeText = 4;
        break;
      case "dental":
        typeText = 6;
        break;
      case "drug":
        typeText = 5;
        break;
      case "drg":
        typeText = 9;
        break;
      case "service":
        typeText = 8;
        break;

      default:
        break;
    }
    axios
      .get(KayanURL + "api/getAllActivity/" + typeText)
      .then(({ data }) => {
        if (data) {
          data.forEach((element) => {
            if (
              ActivityItems.indexOf(element.code + "-" + element.shortdesc) ==
              -1
            ) {
              if (element.shortdesc === "null") {
                element.shortdesc = "";
              }

              ActivityItems.push(element.code + "-" + element.shortdesc);
            }
          });

          this.setState({ ActivityItems: ActivityItems });
        }
      })
      .catch(() => {
        this.setState({ serverError: "There are an server error" });
      });
  };
  renderActivityProgressBar() {
    let { ActivityNow, ActivityItems } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow: !ActivityItems ? ActivityNow + 20 : 100,
        },
        this.renderActivityProgressBar()
      );
    }, "800");
  }
  renderActivityProgressBar_Search() {
    let { ActivityNow1, ActivityItems_Search } = this.state;

    setTimeout(() => {
      this.setState(
        {
          ActivityNow1: !ActivityItems_Search ? ActivityNow1 + 20 : 100,
        },
        this.renderActivityProgressBar_Search()
      );
    }, "800");
  }

  async search(e) {
    e.preventDefault();
    this.setState({
      responseReady: false,
      sent: true,
      status: false,
      isSearch: true,
    });
    let result = {};
    let resultData;
    let errors = [];
    let dataResult;
    const data = await axios.put(KayanURL + "api/dashboard/getReport", {
      type: this.state.type,
      code: this.state.code,
      claimid: this.state.claimID,
      providerid: this.state.providerID,
      encounterFromDate: this.state.encounterFromDate,
      encounterToDate: this.state.encounterToDate,
      activityFromDate: this.state.activityFromDate,
      activityToDate: this.state.activityToDate,
      apiToken: Cookies.get("id"),
    });

    let response = data.data;
    if (response) {
      if (response.success === true) {
        resultData = response.result;
        if (resultData.length == 0) {
          result = { message: "There is no report data", res: false };
          this.setState({ resultMassage: true });
        } else {
          dataResult = response.result;
        }
      }
      if (response.success === false) {
        if (response.error) {
          result = { message: response.error, res: false };
        } else if (response.errors) {
          result = { message: response.errors, res: false };
          errors = result.message;
        }
      }
      this.setState({
        status: result,
        sent: false,
        responseReady: true,
        isSearch: false,
        errors,
        dataResult,
      });
    }
  }
  handleFromDate({ target: { name, value } }) {
    if (value) {
      let date_time = value.split("T");
      if (date_time) {
        let date = date_time[0]; // mm-dd-yyyy -----> dd/mm/yyyy
        date = date.replace(/\-/g, "/").split("/");
        let date2 = date[0] + "/" + date["1"] + "/" + date["2"];
        date2 = date2.split("/").reverse().join("/");
        let time = date_time[1];
        let finalDateTime = date2 + " " + time;
        if (name == "encounterFromDate") {
          this.setState({
            encounterFromDate: finalDateTime,
          });
        } else if (name == "encounterToDate") {
          this.setState({
            encounterToDate: finalDateTime,
          });
        } else if (name == "activityFromDate") {
          this.setState({
            activityFromDate: finalDateTime,
          });
        } else if (name == "activityToDate") {
          this.setState({
            activityToDate: finalDateTime,
          });
        }
      }
    }
  }

  onChange = ({ target: { name, value } }, listName) => {
    if (value.length <= 1) {
      value = value.trim();
    }

    switch (listName) {
      default:
        this.setState({
          section: "activities",
        });
        break;
    }
    if (listName === "code" && value === "") {
      this.setState({
        BtnActive: false,
        userInputActivity: "",
      });
    } else {
      /* this.setState({ BtnActive: true }); */
      const suggestions = this.state.ActivityItems;
      let filteredSuggestions = [];
      if (value.length > 1) {
        const regex = new RegExp(`${value.toLowerCase()}`);
        filteredSuggestions = suggestions
          .sort()
          .filter((v) => regex.test(v.toLowerCase()));
      }

      // Update the user input and filtered suggestions, reset the active
      // suggestion and make sure the suggestions are shown
      this.setState({
        activeSuggestion: 0,
        filteredSuggestionsActivity: filteredSuggestions,
        showActivitySuggestions: true,
        userInputActivity: value,
      });
    }
  };

  onClick = (e, Code) => {
    Code = e.currentTarget.innerText;
    // Update the user input and reset the rest of the state

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showActivitySuggestions: false,
      showActivitySuggestions_search: false,
    });

    if (
      this.state.filteredSuggestionsActivity &&
      this.state.filteredSuggestionsActivity.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity: e.currentTarget.innerText,
        filteredSuggestionsActivity: [],
        BtnActive: true,
      });
    }
    if (
      this.state.filteredSuggestionsActivity_search &&
      this.state.filteredSuggestionsActivity_search.length > 0
    ) {
      this.setState({
        // userInputActivity:this.state.filteredSuggestionsActivity[this.state.activeSuggestion]
        userInputActivity_search: e.currentTarget.innerText,
        filteredSuggestionsActivity_search: [],
        BtnActive_search: true,
      });
    }
    //push to code
    this.setState({
      Code: { ...this.state.code, Text: Code },
    });
  };

  addNewCode = (event, listName, recordId) => {
    event.preventDefault();
    this.setState({
      BtnActive: false,
      BtnActive_search: false,
    });
    let { code, Code, ActivitySearch } = this.state;

    let newcode = Code.Text.split("-");

    switch (listName) {
      default:
        if (code.length < 1) {
          code = newcode[0];
        } else {
          code = newcode[0];
        }

        this.setState({
          // data: { ...this.state.data,code},
          code,
          code: {},
          userInputActivity_search: "",
          userInputActivity: "",
        });
    }
  };

  render() {
    let { status, responseReady, resultMassage, errors, dataResult, isSearch } =
      this.state;
    let errorMap = [];
    let BayanHeader = [
      /*  { label: "ID", key: "id" }, */
      { label: "Claim ID", key: "claimid" },
      { label: "Provider ID", key: "providerid" },
      { label: "Type", key: "type" },
      { label: "Code", key: "code" },

      { label: "Activity Date", key: "activitydate" },
      { label: "Encounter Start Date", key: "encounterstartdate" },
      { label: "Message", key: "message" },
      { label: "Suggested Codes Array", key: "suggestedcodesarray" },
      { label: "Denial", key: "denial" },
    ];
    let fileNameCsv = "report.csv";
    if (errors) {
      errorMap = errors.map((err, index) => {
        return (
          <tr style={{ color: "red" }} key={index}>
            <td>{err.error}</td>
          </tr>
        );
      });
    }
    let AllResultTable;
    if (dataResult && dataResult.length > 0) {
      AllResultTable = dataResult.map((row, index) => {
        return (
          <tr key={index}>
            {/*  <td>{row.id}</td> */}
            <td>{row.claimid ? row.claimid : ""}</td>
            <td>
              {row.providerid && row.providerid != "undefined"
                ? row.providerid
                : ""}
            </td>
            <td>{row.type ? row.type : ""}</td>
            <td>{row.code ? row.code : ""}</td>
            <td>
              {row.activitydate && row.activitydate != "undefined"
                ? row.activitydate
                : ""}
            </td>
            <td>
              {row.encounterstartdate && row.encounterstartdate != "undefined"
                ? row.encounterstartdate
                : ""}
            </td>
            <td>{row.message ? row.message : ""}</td>
            <td>{row.suggestedcodesarray ? row.suggestedcodesarray : ""}</td>
            <td>{row.denial ? row.denial : ""}</td>
          </tr>
        );
      });
    }
    return (
      <>
        <div className="container" style={{ marginLeft: "30px" }}>
          <form onSubmit={this.search.bind(this)} style={{ width: "100%" }}>
            <div className="row" style={{ textAlign: "left" }}>
              <div className="col-md-6">
                {" "}
                <h6 style={{ fontWeight: "bold", color: "#212529" }}>
                  Claim ID
                </h6>
                <div>
                  <input
                    // required
                    onChange={this.handleInputTextChange}
                    type="text"
                    name="claimID"
                    placeholder="claimID"
                    style={{ width: "100%", padding: "8px", textAlign: "left" }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h6
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                  }}
                >
                  Provider ID
                </h6>
                <div>
                  <input
                    // required
                    onChange={this.handleInputTextChange}
                    name="providerID"
                    type="text"
                    placeholder="providerID"
                    style={{
                      width: "100%",
                      padding: "8px",
                      textAlign: "left",
                    }}
                  />
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: "20px", textAlign: "left" }}
            >
              <div className="col-md-6">
                {" "}
                <h6
                  style={{
                    textAlign: "left",
                    fontWeight: "bold",
                    color: "#212529",
                  }}
                >
                  Code Type
                </h6>
                <div>
                  <select className={selectType.selectType}
                  name="type"
                  onChange={(e) => this.handleInputTextChange(e)}
                  >
                    <option value="">select type...</option>
                    <option value="cpt">CPT</option>
                    <option value="hcpcs">HCPCS</option>
                    <option value="dental">Dental</option>
                    <option value="drug">Drug</option>
                    <option value="drg">DRG</option>
                    <option value="service">Service</option>
                  </select>
                  {/* <MuiSelect
                    variant="outlined"
                    name="type"
                    style={{
                      textAlign: "left",
                      width: "100%",
                    }}
                    onChange={(e) => this.handleInputTextChange(e)}
                  >
                    <MenuItem value="cpt">CPT</MenuItem>
                    <MenuItem value="hcpcs">HCPCS</MenuItem>
                    <MenuItem value="dental">Dental</MenuItem>
                    <MenuItem value="drug">Drug</MenuItem>
                    <MenuItem value="drg">DRG</MenuItem>
                    <MenuItem value="service">Service</MenuItem>
                  </MuiSelect> */}
                </div>
              </div>

              <div className="col-md-6">
                {" "}
                {/*   <div style={{ marginTop: "12px" }}>
                  <NewForm
                    inputsTypes={["Search3"]}
                    elementsNames={["code"]}
                    inputStyle="ITRule"
                    submitStyle="BS1"
                    section={this.state.section}
                    addNewCode={this.addNewCode}
                    onChange={this.onChange}
                    onClick={this.onClick}
                    BtnActive={this.state.BtnActive}
                    activeSuggestion={this.state.activeSuggestion}
                    allActivity={this.state.ActivityItems}
                    userInputActivity={this.state.userInputActivity}
                    filteredSuggestionsActivity={
                      this.state.filteredSuggestionsActivity
                    }
                    showActivitySuggestions={this.state.showActivitySuggestions}
                  />
                </div> */}
                <h6 style={{ fontWeight: "bold", color: "#212529" }}>Code</h6>
                <div>
                  <input
                    // required
                    onChange={this.handleInputTextChange}
                    type="text"
                    name="code"
                    placeholder="Code"
                    style={{ width: "100%", padding: "8px", textAlign: "left" }}
                  />
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: "20px" }} /* style={{ textAlign: "left" }} */
            >
              <div className="col-md-4">
                {" "}
                <h6
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    marginTop: "10px",
                  }}
                >
                  Encounter Date Range:
                </h6>
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    display: "inline-block",
                    marginTop: "9px",
                    marginRight: "1px",
                  }}
                >
                  From
                </label>
                <input
                  // required
                  name="encounterFromDate"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                  style={{
                    width: "85%",
                    padding: "8px",
                    textAlign: "left",
                  }}
                />
              </div>
              <div className="col-md-4">
                {" "}
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    display: "inline-block",
                    marginTop: "9px",
                    marginRight: "7px",
                  }}
                >
                  To
                </label>
                <input
                  // required
                  name="encounterToDate"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                  style={{
                    width: "85%",
                    padding: "8px",
                    textAlign: "left",
                  }}
                />
              </div>
            </div>

            <div
              className="row"
              style={{ marginTop: "20px" }} /* style={{ textAlign: "left" }} */
            >
              <div className="col-md-4">
                {" "}
                <h6
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    marginTop: "15px",
                  }}
                >
                  Activity Date Range:
                </h6>
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    display: "inline-block",
                    marginTop: "9px",
                    marginRight: "1px",
                  }}
                >
                  From
                </label>
                <input
                  // required
                  name="activityFromDate"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                  style={{
                    width: "85%",
                    padding: "8px",
                    textAlign: "left",
                  }}
                />
              </div>
              <div className="col-md-4">
                <label
                  style={{
                    fontWeight: "bold",
                    color: "#212529",
                    display: "inline-block",
                    marginTop: "9px",
                    marginRight: "7px",
                  }}
                >
                  To
                </label>
                <input
                  // required
                  name="activityToDate"
                  onChange={this.handleFromDate}
                  type="datetime-local"
                  style={{
                    width: "85%",
                    padding: "8px",
                    textAlign: "left",
                  }}
                />
              </div>
            </div>
            <input type="submit" name="name" value="Generate" className="BS1" />
          </form>
          {responseReady && status.res ? (
            <h5 style={{ color: "green" }}>{status.message}</h5>
          ) : responseReady && status.res == false ? (
            <h5 style={{ color: "red" }}>{status.message}</h5>
          ) : responseReady && status.res == false && resultMassage ? (
            <h5 style={{ color: "red" }}>{status.message}</h5>
          ) : responseReady && status.res == false && errors ? (
            <div className="w-50 m-auto">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>error</th>
                  </tr>
                </thead>
                <tbody>{errorMap}</tbody>
              </table>
            </div>
          ) : null}
        </div>
        <div className="container" style={{ marginLeft: "30px" }}>
          <div style={{ boxSizing: "border-box", maxWidth: "100%" }}>
            {isSearch ? <Spinner animation="border" variant="success" /> : null}
            {dataResult && dataResult.length > 0 ? (
              <>
                <div style={{ marginBottom: "20px" }}>
                  <CSVLink
                    filename={fileNameCsv}
                    style={{ color: "#ff0505", textDecoration: "underline" }}
                    data={dataResult}
                    headers={BayanHeader}
                  >
                    Please click here to download the report
                  </CSVLink>{" "}
                </div>
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  size="sm"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <thead
                    style={{
                      background: "#3ab449",
                      color: " white",
                      verticalAlign: "middle",
                    }}
                  >
                    <tr>
                      {/*  <th>ID</th> */}
                      <th>ClaimID</th>
                      <th>ProviderID</th>
                      <th>Code</th>
                      <th>Type</th>
                      <th>Activity Date</th>
                      <th>Encounter Start Date</th>
                      <th>Message</th>
                      <th>Suggested Codes Array</th>
                      <th>Denial</th>
                    </tr>
                  </thead>
                  <tbody>{AllResultTable}</tbody>
                </Table>
              </>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default MainScreenBayan;
